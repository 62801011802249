import React, { useState, useEffect, FormEvent } from "react";
import axios from "axios";
import moment from "moment";
import Checkbox from "../Components/Checkbox";
import { useLocation } from "react-router-dom";
import CheckCircle from "../Components/Icons/CheckCircle";
import { DatePicker, DatesProvider, DatePickerProps, getMonthDays, DateValue } from "@mantine/dates";
import { ITechnicianBooking, PickServiceDateModel } from "../types/types";
import "../App.css";
import "dayjs/locale/da";
import "react-multi-carousel/lib/styles.css";

let ENV = "PROD";

const API = ENV === "PROD" ? "https://integration.bwt.dk/api" : "http://localhost:8080/api";

const initialFormState: PickServiceDateModel = {
	bookService: {
		customerNumber: null,
		AS400OrderId: null,
		LEVLQB: null,
		customer: null,
		postalCode: null,
		anlaegsType: null,
		userId: null,
		phone: null,
		OPDTD: null,
		OPDTS: null,
		fileUploadedToSD: null,
		technicianPrivateServiceBookingId: null,
		createdAt: null,
		updatedAt: null,
	},
	date: null,
	time: null,
	message: null,
	callForQuickOrder: false,
};

const BookService: React.FC = () => {
	const [formData, setFormData] = useState<PickServiceDateModel>(initialFormState);

	const [time, setTime] = useState<string | null>(null);
	const [userId, setUserId] = useState<number | null>(null);
	const [postal, setPostalCode] = useState<string | null>(null);
	const [technicianAvailableDates, setTechnicianAvailableDates] = useState<[string] | []>([]);
	const [availableTimeSlots, setAvailableTimeSlots] = useState<[ITechnicianBooking] | []>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const location = useLocation();

	const [loadingServiceBooking, setLoadingServiceBooking] = useState<boolean>(true);

	useEffect(() => {
		let { search } = location;
		let id: string = "",
			postal: string = "";
		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("id" in searchJSON) {
				id = searchJSON["id"];
			}

			if ("postal" in searchJSON) {
				postal = searchJSON["postal"];
			}

			if (id && postal) {
				setUserId(parseInt(id));
				setPostalCode(postal);
			} else {
				setLoadingServiceBooking(false);
			}
		}
	}, []);

	// Henter dage hvor teknikeren har 1 eller flere ledige tider
	useEffect(() => {
		console.log(getMonthDays);
		const fetchData = async () => {
			const date = formData.date;
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : "";
				const response = await axios.get(`${API}/technicianbooking/all/${userId}/${_date}/${postal}/anything"`);
				setTime(null);
				setAvailableTimeSlots(response.data.teknikerBookings);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.date !== null && isModalOpen !== true && userId !== null && userId > 0) {
			fetchData();
		}
	}, [formData.date, userId]);

	// Henter ledige tider når kunden vælger en dato
	useEffect(() => {
		console.log(getMonthDays);
		const fetchData = async () => {
			const date = formData.date;
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : "";
				const response = await axios.get(`${API}/technicianbooking/all/${userId}/${_date}/${postal}/${formData.bookService.anlaegsType}`);
				setTime(null);
				setAvailableTimeSlots(response.data.teknikerBookings);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.date !== null && isModalOpen !== true && userId !== null) {
			fetchData();
		}
	}, [formData.date, userId, formData.bookService.postalCode]);

	// Her hender vi teknikerens ledige datoer
	useEffect(() => {
		const fetchData = async () => {
			try {
				const postalCode = formData.bookService.postalCode;

				const response = await axios.get(`${API}/technicianbooking/availabledates/${userId}/${postal}/${formData.bookService.anlaegsType}/anything`);
				setTechnicianAvailableDates(response.data.teknikerBookingDates);
				console.log(response.data);
				console.log("Henter datoer igen for teknikeren " + userId + " og postnummer " + postalCode);
				setLoadingServiceBooking(false);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};

		if (userId !== null && formData.date === null) {
			fetchData();
		}
	}, [formData.bookService.postalCode, userId]); // Empty array as the second argument to run effect only once (on mount)

	function getDateInFuture(amount: number): Date {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() + amount);
		return yesterday;
	}

	const getDayProps: DatePickerProps["getDayProps"] = (_date) => {
		// If the day is saturday or sunday, then disable it
		if (_date.getDay() === 6 || _date.getDay() === 0) {
			return { disabled: true };
		}

		// console.log(technicianAvailableDates);

		if (technicianAvailableDates === undefined) {
			return { disabled: true };
		}

		if (technicianAvailableDates.length <= 0) {
			return { disabled: true };
		}

		const available = technicianAvailableDates.some((item) => item === _date.toISOString().split("T")[0]);

		const date = formData.date;

		if (date !== null && _date !== null) {
			if (_date.toISOString().split("T")[0].replace(/-/g, "") === date.toISOString().split("T")[0].replace(/-/g, "")) {
				return {
					style: {
						backgroundColor: "#00174B",
						color: "var(--mantine-color-white)",
						selected: "red",
					},
				};
			}
		}

		if (available) {
			return {
				style: {
					backgroundColor: "#00174B",
					opacity: 0.72,
					color: "var(--mantine-color-white)",
					selected: "red",
				},
			};
		} else {
			return { disabled: true };
		}
	};

	// Hvis året er det samme som i dag, så gør teksten blå og fed på årsknapperne
	const getYearControlProps: DatePickerProps["getYearControlProps"] = (date) => {
		if (date.getFullYear() === new Date().getFullYear()) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getFullYear() === new Date().getFullYear() + 1) {
			return { disabled: true };
		}

		return {};
	};

	// Hvis måneden er februar, så gør teksten blå og fed på månedknapperne
	const getMonthControlProps: DatePickerProps["getMonthControlProps"] = (date) => {
		if (date.getMonth() === 1) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getMonth() === 5) {
			return { disabled: true };
		}

		return {};
	};

	const handleDateChanged = (date: DateValue) => {
		setFormData({
			...formData,
			date: date,
		});
	};

	return (
		<>
			<div className="max-w-3xl mx-auto mt-4 mb-4 md:mb-6 px-1 md:px-0">
				<div className="max-h-8 md:max-h-12 flex h-screen">
					<div>
						<img className="max-h-8 md:max-h-14" src="./BWT_Logo_with_Subline_Positive.png" alt="bwtchangetheworldlogo" />
					</div>
					<div className="m-auto mr-0 ">
						<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">Bestil BWT servicetekniker</h3>
					</div>
				</div>
			</div>

			{loadingServiceBooking ? (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<div className={`my-4`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<Loader color="red" />
							</div>
						</div>
					</div>
				</div>
			) : null}

			{!loadingServiceBooking ? (
				<div>
					<div className="border-x border max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-lg">
						<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
							<div className={`flex items-center`}>
								<p className="text-md md:text-lg">Vælg dato og tid for dit næste servicebesøg</p>
							</div>
						</div>
						<div>
							<h1 className="">
								Det er nu blevet tid til det faste servicebesøg i og med du har en BWT Tryghed Premium aftale. Vælg venligst en dato og tid, hvor vores
								BWT servicetekniker kan komme forbi og servicere dit anlæg.
							</h1>
						</div>
						<div className={` mt-6`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<div className={`text-gray-600 items-center flex justify-center`}>
									<DatesProvider settings={{ consistentWeeks: true, locale: "da-DK", firstDayOfWeek: 1, timezone: "GMT" }}>
										<DatePicker
											className="align-center"
											minDate={getDateInFuture(1)}
											maxDate={getDateInFuture(180)}
											allowDeselect
											value={formData.date}
											onChange={(date) => handleDateChanged(date)}
											getDayProps={getDayProps}
											getYearControlProps={getYearControlProps}
											getMonthControlProps={getMonthControlProps}
										/>
									</DatesProvider>
								</div>
								<div className="mt-2 text-sm">
									{formData.date !== null ? (
										<div className="border p-2 text-center bg-white shadow-lg">
											Ledige tider
											<br /> {formData.date !== null ? moment(formData.date.toISOString().split("T")[0]).format("DD-MM-YYYY") : ""}
										</div>
									) : (
										<div className="border p-4 text-center bg-white shadow-lg">Vælg en dato for at se ledige tider</div>
									)}

									<div className="grid grid-cols-1 gap-2 my-4">
										{formData.date !== null && availableTimeSlots.length > 0
											? availableTimeSlots.map((item) => (
													<button
														key={item.id}
														className={`w-full  py-2  hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
															time === item.timeSlot ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"
														}`}
														onClick={() => {
															setTime(item.timeSlot);
														}}
													>
														{item.timeSlot}
													</button>
											  ))
											: null}

										{formData.date !== null && availableTimeSlots.length === 0 ? <p className="text-center">Ingen ledige tider</p> : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

const Loader: React.FC<{ color: string }> = ({ color }) => {
	return (
		<div className="flex justify-center items-center">
			<div className={`loader ease-linear rounded-full border-8 border-t-8 border-${color}-200 h-24 w-24`}></div>
		</div>
	);
};

export default BookService;
